import "./assets/PortalTheme.css";

import cssVars from "css-vars-ponyfill";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { Redirect } from "react-router";
import * as router from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import favIcon from "./assets/favicons/favicon.gif";
import { API_URL_STATIC, PAGE_BREAK_MOBILE } from "./config/config";
import useWindowSize from "./customhooks/useWindowSize";
import Routes from "./routes/Routes";
import RoutesOwnDomain from "./routes/RoutesOwnDomain";
import ResetMandant from "./store/mandantStore/ResetMandant";
import IApplicationRootState from "./store/StoreTypes";
import IMandant from "./types/IMandant";
import MatomoTracker from "./utils/matomo";
import setMandantThemeByServer from "./utils/setMandantThemeByServer";

// enable inet explorer cssVariables
cssVars({
    // Options...
});

interface IMapStateProps {
    mandant: Partial<IMandant>;
}

interface IMapDispatchProps {
    dispatchResetMandant: () => void;
}

interface IOwnProps {}

interface IAppPros extends IOwnProps, IMapDispatchProps, IMapStateProps {}

function App(props: IAppPros) {
    // eslint-disable-next-line prefer-const
    let { mandant } = props;
    const { dispatchResetMandant } = props;

    if (!mandant || !mandant.key) {
        mandant = {
            primaryColor: "#6bd5fc",
            theme: "sporty",
            key: "",
            partnerLogos: [],
            matomoTrackingId: undefined,
        };
    }

    const cookieConsent = localStorage.getItem(
        "cookieConsent-" + props.mandant.key
    );

    const windowSize = useWindowSize();
    const [isMobile, setIsMobile] = React.useState<boolean | null>(null);

    // Load Matomo tracking code when the component mounts
    useEffect(() => {
        if (mandant.matomoTrackingId && cookieConsent === "all") {
            MatomoTracker.loadTrackingCode(mandant.matomoTrackingId);
        }
    }, [mandant.matomoTrackingId, cookieConsent]);

    useEffect(() => {
        if (windowSize > 319) {
            let flagIsMobile = false;
            if (windowSize < PAGE_BREAK_MOBILE) {
                flagIsMobile = true;
            }

            if (isMobile !== flagIsMobile) {
                // this was moved to line 62 to update the mandant when the user switches the projects.
                // setMandantThemeByServer(mandant, flagIsMobile);
                setIsMobile(flagIsMobile);
            }
            setMandantThemeByServer(mandant, flagIsMobile);
        }
    }, [windowSize, mandant.key]);

    useEffect(() => {
        if (mandant.flagHaveDomain) {
            if (mandant.domain !== window.location.hostname) {
                dispatchResetMandant();
            }
        }
    }, [mandant]);
    /*
    if (mandant.flagHaveDomain) {
        if (mandant.domain !== window.location.hostname) {
            return (
                <div className="App">
                    <Redirect
                        to={
                            "https://" +
                            mandant.domain +
                            "/" +
                            window.location.pathname +
                            window.location.search +
                            window.location.hash
                        }
                    />
                </div>
            );
        }
    }    */

    return (
        <div className="App">
            <Helmet>
                <link
                    rel="icon"
                    type="image/png"
                    href={
                        mandant.favIcon?.url
                            ? API_URL_STATIC + mandant.favIcon?.url
                            : favIcon
                    }
                    sizes="16x16"
                />
            </Helmet>

            {mandant.flagHaveDomain && (
                <router.BrowserRouter>
                    <router.Switch>
                        {RoutesOwnDomain.map((route: any) => {
                            return (
                                <router.Route
                                    key={route.name}
                                    path={route.path}
                                    render={(renderProps: any) => (
                                        <route.component
                                            {...renderProps}
                                            mandant={mandant}
                                        />
                                    )}
                                />
                            );
                        })}
                        {/* if route is not found */}
                        <router.Route
                            key={999}
                            path="*"
                            render={() => <Redirect to="/" />}
                        />
                    </router.Switch>
                </router.BrowserRouter>
            )}

            {!mandant.flagHaveDomain && (
                <router.BrowserRouter>
                    <router.Switch>
                        {Routes.map((route: any) => {
                            return (
                                <router.Route
                                    key={route.name}
                                    path={route.path}
                                    render={(renderProps: any) => (
                                        <route.component
                                            {...renderProps}
                                            mandant={mandant}
                                        />
                                    )}
                                />
                            );
                        })}
                        {/* if route is not found */}
                        <router.Route
                            path="*"
                            render={() => <Redirect to="/" />}
                        />
                    </router.Switch>
                </router.BrowserRouter>
            )}
        </div>
    );
}

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        dispatchResetMandant: () => {
            return dispatch(ResetMandant());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
